<template>
<div class="cart-item">
    <Loader v-if="item.id==clickId" />
    <div class="cart-item__img" v-if="item.files.length!=0">
        <img :src="`https://elimleli2.itfactory.site${item.files[0].thumb_path}`" /> 
        <!-- <img :src="require('../assets/cartitem.jpg')" />  -->
    </div>
    <div class="cart-item__noimg" v-else>
    </div>
    <div class="cart-item__name">
        <!-- <p>id: {{item.id}}</p>
      <p>cartId: {{cartId}}</p> -->
    
        <b class="pb-2">{{ item.name }}</b>
        <span class="cart-item__price-mob d-md-none">{{item.price}} руб.</span>
        <span class="d-none d-md-inline">
        <span class="kkal">{{item.propertyValues['138'].values[0]}} ккал</span> <span class="disc"></span> 
        {{item.propertyValues['143'].values[0]}}гр. <span class="disc"></span>  
        БЖУ:  {{item.propertyValues['141'].values[0]}} / {{item.propertyValues['142'].values[0]}} / {{item.propertyValues['143'].values[0]}}</span>
        
    </div>

    <div class="cart-item__counter">
        <div class="counter">
            <button class="counter__decrement" @click="decrement(item.id)" :disabled="(item.quantity ==1 | decrementDisable==item.id)?true:false">-</button>
            <div class="counter__value">{{ item.quantity }}</div>
            <button class="counter__increment" @click="increment(item.id)" :disabled="(item.quantity >= item.balance|incrementDisable==item.id)?true:false">+</button>
        </div>
    </div>
    <!-- <div class="cart-item__price d-mb-none">
            <span class="">{{ item.price }} руб.</span>
    </div> -->
    <div class="cart-item__descmob d-md-none">
        <b>{{item.propertyValues['138'].values[0]}} ккал</b><br />БЖУ:  {{item.propertyValues['141'].values[0]}} / {{item.propertyValues['142'].values[0]}} / {{item.propertyValues['143'].values[0]}}
    </div>
    <div class="cart-item__price d-none d-md-block">
        <span class="">{{ item.price }} руб.</span>
    </div>
    <template v-if="this.$route.name=='CartEdit'">
        <div class="cart-item__remove" v-if="$store.state.cart.cartEdit.orders.cart_info.cart_items[0].products.length!=1" @click="removeItem({id:item.id, cartId:cartId})">
        <img :src="require('../assets/choice-remove.svg')" />
        </div>
    </template>
    <template v-else>
        <div class="cart-item__remove"  @click="removeItem({id:item.id, cartId:cartId})">
        <img :src="require('../assets/choice-remove.svg')" />
        </div>
    </template>
   
</div>
</template>

<script>
import _ from 'lodash'
export default {
    data() {
        return {
            count: this.item.qt,
            clickId: null,
            incrementDisable:null,
            decrementDisable:null
        }
    },
    props: {
        item: {
            type: Object
        },
        cartId: Number,
        edit: Boolean
    },
    mounted() {
        console.log(this.$route.name)
    },
    methods: {
        async removeItem(data) {
            //alert(data);
            this.clickId = this.item.id
            if (this.edit) {
                await this.$store.dispatch("actionRemoveFromCart", data);
                await this.$store.dispatch("actionGetCartEdit", this.$route.params.id);
                await this.$store.dispatch("actionGetBalance");

            } else {
                await this.$store.dispatch("actionRemoveFromCart", data);
                await this.$store.dispatch('actionGetCart');
                if(this.$store.state.cart.cart?.cart_items?.length==0){
                     this.$router.push("/");
                }
            }
        },
        //  increment() {
        //   this.item.quantity++;
        // },

        increment: _.throttle(async function (id) {
            if (this.edit) {
                 this.incrementDisable = id
                const res = await this.$store.dispatch("actionIncrease", {
                    cartItemId: this.cartId,
                    productId: this.item.id
                });
                //Если недостаточно средств на балансе то алерт
                if(!res.data.status){
                      this.$toast.warning(res.data.message);
                }
                await this.$store.dispatch("actionGetCartEdit", this.$route.params.id);
                this.incrementDisable = null
                await this.$store.dispatch("actionGetBalance");
            } else {
                 this.incrementDisable = id
                await this.$store.dispatch("actionIncrease", {
                    cartItemId: this.cartId,
                    productId: this.item.id
                });
                await this.$store.dispatch('actionGetCart');
                 this.incrementDisable = null
            }

        }, 300),

        decrement: _.throttle(async function (id) {
            if (this.edit) {
                this.decrementDisable = id
                await this.$store.dispatch("actionDecreaseEdit", {
                    cartItemId: this.cartId,
                    productId: this.item.id
                });
                await this.$store.dispatch("actionGetCartEdit", this.$route.params.id);
                 this.decrementDisable = null
                await this.$store.dispatch("actionGetBalance");
            } else {
                 this.decrementDisable = id
                await this.$store.dispatch("actionDecrease", {
                    cartItemId: this.cartId,
                    productId: this.item.id
                });
                await this.$store.dispatch('actionGetCart');
                 this.decrementDisable = null
                 if(this.$store.state.cart.cart?.cart_items?.length==0){
                     this.$router.push("/");
                }
            }

        }, 300),
        // decrement() {
        //   if(this.item.quantity>1)
        //    this.item.quantity--;
        // },
    },
};
</script>

<style lang="scss">
.cart-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #dddddd;
    // border-bottom: 1px solid #DDDDDD;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        padding-top: 14px;
    }

    &__img {
        margin-right: 25px;

        @include media-breakpoint-down(md) {
            order: 1;
            margin-right: 12px;
        }

        img {
            width: 140px;
height: 112px;
border-radius: 12px;
            // max-width: 100%;
            // height: auto;
            @include media-breakpoint-down(md) {
                max-width: 71px;
                height: auto;
            }
        }
    }
    &__noimg{
     height: 112px;
    max-width: 140px;
    width: 100%;
    margin-right: 25px;
      background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;

      border-radius: 12px;
           @include media-breakpoint-down(md) {
                max-width: 71px;
                width: 100px;
               height: 112px;
                 margin-right: 12px;
            }
              @include media-breakpoint-down(sm) {
                 background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
                 background-size: contain;
height: 72px;
              }
    }

    &__name {
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        margin-top: -5px;
        width: 23%;
        font-size: 18px;
        line-height: 1.2;
        @include media-breakpoint-down(md) {
            order: 2;
            width: 60%;
        }

        span {
            font-family: Montserrat;

            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #828282;
        }

        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }

    &__counter {
        padding-right: 112px;

        @include media-breakpoint-down(md) {
            order: 4;
            margin-left: auto;
            width: 50%;
            padding-right: 0 !important;
        }
    }

    &__price {
        font-weight: 600;
        font-size: 16px;
        font-weight: 600;
        line-height: 142.69%;
        padding-right: 60px;

        @include media-breakpoint-down(md) {
            order: 3;
            white-space: nowrap;
        }
    }

    &__remove {
        cursor: pointer;

        @include media-breakpoint-down(md) {
            order: 3;
            position: absolute;
            top: 28px;
            right: 11px;
        }
    }
}

.counter {
    font-family: Montserrat;
    font-size: 16px;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 12px;
    line-height: 142.69%;
    height: 40px;

    @include media-breakpoint-down(md) {
        width: 106px;
        margin-left: auto;
    }

    &__decrement {
        width: 40px;
        text-align: center;
      //  cursor: pointer;
    }

    &__value {
        font-weight: 600;
        text-align: center;
        min-width: 25px;
    }

    &__increment {
        width: 40px;
        text-align: center;
        //cursor: pointer;
    }
}

.cart-item__descmob {
    order: 3;
    width: 50%;
    font-size: 12px;
    line-height: 20px;
    font-family: Montserrat;
    color: #828282;
    margin-top: 8px;
      @include media-breakpoint-down(md){
        font-weight: 400 !important;
      }
}

.cart-item {
    position: relative;
}
.kkal{
    color:#000;
}
.disc{
    display: inline-block;
    width: 4px;
    height: 4px;
    background: #828282;
    border-radius: 50%;
    position: relative;
    top: -2px;
}
.cart-item__price-mob{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400 !important;
font-size: 16px !important;
color: #000000 !important;
}
.counter__decrement{
    border: none;
}
.counter__increment{
    border: none;
}
</style>
